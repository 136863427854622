*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
  font-family: inherit;
}

html {
  // This defines what 1rem is
  font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%
  scroll-behavior: smooth;

  @include respond(tab-land) {
    // width < 1200?
    font-size: 59%; //1 rem =
  }

  @include respond(tab-port) {
    // width < 900?
    font-size: 56%; //1 rem =
  }

  @include respond(big-desktop) {
    font-size: 65%; //1rem =
  }
}

body {
  box-sizing: border-box;
  position: relative;
  line-height: 1.5;
  font-family: sans-serif;
  overflow-x: hidden;
  overflow-y: scroll;
  font-family: 'Source Sans Pro', sans-serif;
  
}

a {
  text-decoration: none;
  color: inherit;
}

li {
  list-style: none;
}

input:focus,
button:focus,
a:focus,
textarea:focus {
  outline: none;
}

button {
  border: none;
  cursor: pointer;
}

textarea {
  resize: none;
}
