.skills {
  display: flex;
  flex-wrap: wrap;
  &__skill {
    padding: 1rem 2rem;
    margin-bottom: 1.5rem;
    margin-right: 1.5rem;
    font-size: 1.6rem;
    background: rgba(#999, 0.2);
    border-radius: $commonBorderRadius;
    font-weight: 600;
    color: #777;
  }
}
