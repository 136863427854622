@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700;900&display=swap');

@import 'abstracts/variables';
@import 'abstracts/mixins';


@import 'base/base';

@import 'abstracts/utilities';

@import 'pages/home';
@import 'pages/project-case-study';

@import 'components/header';
@import 'components/footer';
@import 'components/skills';
@import 'components/mouse-scroll';







